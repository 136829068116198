import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { injectIntl } from "gatsby-plugin-intl";

function Seo(props) {
	const { description, meta, title, intl, calificacionCentro, reviewsQty } =
		props;
	const site = {
		siteMetadata: {
			siteUrl: "siteUrl",
			es: {
				title: `Fresh Snow`,
				description: `Partes de nieve detallado, Pronósticos de Nieve a 14 días, Condiciones Meteorológicos en Tiempo Real, Reportes y calificacion de usuarios, Estado de la nieve`,
				author: `Living Crowdland SL`,
			},
			en: {
				title: `Fresh Snow`,
				description: `Detailed snow reports, 14-day Snow Forecasts, Real-time Weather Conditions, User Reports and Ratings, Snow Status`,
				author: `Living Crowdland SL`,
			},
			ca: {
				title: `Fresh Snow`,
				description: `Detailed snow reports, 14-day Snow Forecasts, Real-time Weather Conditions, User Reports and Ratings, Snow Status`,
				author: `Living Crowdland SL`,
			},
			fr: {
				title: `Fresh Snow`,
				description: `Detailed snow reports, 14-day Snow Forecasts, Real-time Weather Conditions, User Reports and Ratings, Snow Status`,
				author: `Living Crowdland SL`,
			},
		},
	};

	const metaDescription =
		description || site.siteMetadata[intl.locale].description;

	return (
		<Helmet
			htmlAttributes={{
				lang: intl.locale,
			}}
			defautTitle={site.siteMetadata[intl.locale].title}
			titleTemplate={`${title} | ${site.siteMetadata[intl.locale].title}`}
			title={`${title} | ${site.siteMetadata[intl.locale].title}`}
			meta={[
				{
					name: `description`,
					content: metaDescription,
				},
				{
					property: `og:title`,
					content: `${title} | ${site.siteMetadata[intl.locale].title}`,
				},
				{
					property: `og:description`,
					content: metaDescription,
				},
				{
					property: `og:type`,
					content: `website`,
				},
				{
					name: `twitter:card`,
					content: `summary`,
				},
				{
					name: `twitter:creator`,
					content: site.siteMetadata[intl.locale].author,
				},
				{
					name: `twitter:title`,
					content: `${title} | ${site.siteMetadata[intl.locale].title}`,
				},
				{
					name: `twitter:description`,
					content: metaDescription,
				},
				{
					name: "google-site-verification",
					content: "NNXZEOS32ajTWLwZwC4uhvv3OkKTICc9dVVyScnUJ90",
				},
			].concat(meta)}
		>
			{calificacionCentro !== undefined && reviewsQty !== undefined && (
				<script type="application/ld+json">
					{JSON.stringify({
						"@context": "https://schema.org/",
						"@type": "AggregateRating",
						itemReviewed: {
							"@type": "LocalBusiness",
							name: `${title} | ${site.siteMetadata[intl.locale].title}`,
							address: {},
						},
						ratingValue:
							calificacionCentro < 0 || calificacionCentro === null
								? 0
								: calificacionCentro,
						bestRating: 10,
						ratingCount:
							reviewsQty < 0 || reviewsQty === null ? 0 : reviewsQty,
					})}
				</script>
			)}
		</Helmet>
	);
}

Seo.defaultProps = {
	lang: `es`,
	meta: [],
	description: ``,
};

Seo.propTypes = {
	description: PropTypes.string,
	lang: PropTypes.string,
	meta: PropTypes.arrayOf(PropTypes.object),
	title: PropTypes.string.isRequired,
};

export default injectIntl(Seo);
